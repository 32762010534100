.card{
  z-index: 0;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  border-radius: 30px;
  border: 2px solid transparent;
  padding-bottom: 20px;
  margin-top: 90px;
  margin-bottom: 90px;
  width: 65vw;
  margin-left: auto;
  margin-right: auto;
}

.card1 {
  z-index: 0;
  background: linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 26px 26px 0px 0px;
}
.top {
  padding-top: 40px;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455a64;
  margin-top: 30px;
}

#progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 15.4%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar .step0:before {
  font-family: FontAwesome;
  content: "\f10c";
  color: transparent;
}

#progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  background: #c5cae9;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 5px;
  background: #c5cae9;
  position: absolute;
  left: 0;
  top: 18px;
  z-index: -1;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after,
#progressbar li:nth-child(4):after,
#progressbar li:nth-child(5):after {
  left: -44%;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background:  #f529ad ;
}

#progressbar li.active1:before,
#progressbar li.active1:after {
  background:  #E330AD ;
}

#progressbar li.active2:before,
#progressbar li.active2:after {
  background:  #CF37AD ;
}

#progressbar li.active3:before,
#progressbar li.active3:after {
  background:  #BA3FAD ;
}

#progressbar li.active4:before,
#progressbar li.active4:after {
  background:  #A547AD ;
}

#progressbar li.active5:before,
#progressbar li.active5:after {
  background:  #944DAD;
}

#progressbar li.active:before {

}

.icon-content {
  padding-bottom: 20px;
}

#progressbar2 {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455a64;
  margin-top: 30px;
}

#progressbar2 li {
  list-style-type: none;
  font-size: 13px;
  width: 15.4%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar2 .step0:before {
  font-family: FontAwesome;
  content: "\f10c";
  color: transparent;
}

#progressbar2 li:before {
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  background: #c5cae9;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

#progressbar2 li:after {
  content: "";
  width: 100%;
  height: 5px;
  background: #c5cae9;
  position: absolute;
  left: 0;
  top: 18px;
  z-index: -1;
}

#progressbar2 li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar2 li:nth-child(2):after,
#progressbar2 li:nth-child(3):after,
#progressbar2 li:nth-child(4):after,
#progressbar2 li:nth-child(5):after {
  left: -44%;
}

#progressbar2 li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar2 li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar2 li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#progressbar2 li.active:before,
#progressbar2 li.active:after {
  background:  #77CCD5 ;
}

#progressbar2 li.active1:before,
#progressbar2 li.active1:after {
  background:  #77CCD5 ;
}

#progressbar2 li.active2:before,
#progressbar2 li.active2:after {
  background:  #77CCD5 ;
}

#progressbar2 li.active3:before,
#progressbar2 li.active3:after {
  background:  #77CCD5 ;
}

#progressbar2 li.active4:before,
#progressbar2 li.active4:after {
  background:  #77CCD5 ;
}

#progressbar2 li.active5:before,
#progressbar2 li.active5:after {
  background:  #77CCD5;
}

@media screen and (max-width: 992px) {
  .icon-content {
    width: 50%;
  }
}
