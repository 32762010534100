.contapp {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    min-height: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inner {
    display: flex;
  }
  
  .item {
    min-height: 350px;
    min-width: 450px;
    padding: 30px;
  }
  
  .item img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    pointer-events: none;
  }
  
  .carousel {
    cursor: grab;
    overflow: hidden;
  }
  