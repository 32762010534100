.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-left: 0px;
  margin-right: 0px;
}

.page-item.active .page-link {
  z-index: 3;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  color: #00AD90;

  background-color: white;
  border-bottom: 2px solid #00AD90;
  border-bottom-style: ridge;
}

.page-link {
  position: relative;
  display: block;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
  text-decoration: none;
  background-color: #fff;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #e2e2e2;
  background-clip: padding-box;
  opacity: 1;
  transition: opacity 0.6s ease;
}
.carousel-indicators .active {
  opacity: 1;
  width: 22px;
  height: 12px;
  border-top-left-radius: 10px;
  background-color: #00AD90;
}
