.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .checkbox {
    /*   display: block; */
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 4px;
    /*   border: 2px solid #eee;
      background-color: #eee; */
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox:hover input ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox input:checked ~ .checkmark {
    /*   background-color: #eee; */
    background-color: var(--accepted);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #eee;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  /* 
    custom
    */
  :root {
    --gluten: #F99D56;
    --cafeina: #FB5B6D;
    --lactosa: #009899;
    
  }


/*  Checkbox  */
  /* Gluten */
  .checkbox input ~ .gluten {
    border: 2px solid var(--gluten) !important;
  }
  
  .checkbox:hover input ~ .gluten,
  .checkbox input:checked ~ .gluten {
    background-color: var(--gluten);
  }
  /* Cafeina */
  .checkbox input ~ .cafeina {
    border: 2px solid var(--cafeina) !important;
  }
  .checkbox:hover input ~ .cafeina,
  .checkbox input:checked ~ .cafeina {
    background-color: var(--cafeina);
  }
  /* lactosa */
.checkbox input ~ .lactosa {
  border: 2px solid var(--lactosa) !important;
}
.checkbox:hover input ~ .lactosa,
.checkbox input:checked ~ .lactosa {
  background-color: var(--lactosa);
}

  /*****Radio*****/

  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #eee;
    /* Not removed via appearance */
    margin: 0;
  
    font: inherit;
    color: currentColor;
    width: 1.8em;
    height: 1.8em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
  }
  
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--cafeina);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  
  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }
  
