/* My styles */
.all-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    flex-direction: row;
}

.full-width {
    width: 100%;
}

.column {
    flex-direction: column;
}

/* Toggle Button */
.btn-group {
    background-color: #f6f6f6;
    border-radius: 86px;
}

#radio-0 + label {
    border: none;
    border-radius: 86px;
    background: #00ad90;
    color: white;
    font-weight: bold;
}

#radio-1 + label {
    border: none;
    border-radius: 86px;
    font-weight: bold;
    background-color: #f6f6f6;
}

#radio-0:focus,
#radio-1:focus {
    outline: none;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.mabo-5px {
    margin-bottom: 5px;
}
.mabo-8px {
    margin-bottom: 8px;
}

/* Radio buttons (edit profile) */
.form-check-input:checked {
    background-color: #00ad90;
    border-color: #00ad90;
}

#register-email + label {
    padding-left: 10px;
}

#check-newsletter + label,
#radio-masculino + label,
#radio-femenino + label {
    padding-left: 10px;
    font-size: 16px;
}

input[type="radio"]#radio-masculino,
input[type="radio"]#radio-femenino {
    border: 1px solid rgb(87, 49, 178);
    width: 7%;
    height: 1.3em;
}

.form-check-input[type="checkbox"]#check-newsletter {
    border: 1px solid #00ad90;
    width: 6%;
    height: 1.3em;
}
.form-check-input[type="checkbox"]#register-email {
    border: 1px solid #00ad90;
    width: 8%;
    height: 1.3em;
}

@media only screen and (max-width: 600px) {
    input[type="radio"]#radio-masculino,
    input[type="radio"]#radio-femenino {
        width: 6.5%;
        height: 1.3em;
    }
}
