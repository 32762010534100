
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --green: #00ad92;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-gn {
  box-shadow: none !important;
  border-radius: 25px;
  width: 110px;
  font-size: 17px;
}

.btn-green {
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
}

.btn-red {
  background-color: red !important;
  border: 1px solid red !important;
}

.btn-green:active:focus,
.btn-green::after,
.btn-green:hover {
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
}

.btn-red:active:focus,
.btn-red::after,
.btn-red:hover {
  background-color: red !important;
  border: 1px solid red !important;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
.float:hover{
	color: #FFF;
}